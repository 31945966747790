import {

    EmailShareButton,
    FacebookShareButton, 
    TwitterShareButton, 
    EmailIcon,
    FacebookIcon,
    TwitterIcon,
  } from "react-share";

import React, { Component } from "react"; 
import queryString from "query-string";

class SocialShare extends Component {
  
constructor(props){
  super(props);
  
  let search =  this.props.ShareInfo;
     this.state = { 
         search:search,
       };
 }
  render () {
    return (
        <div  className="m-3">
          <p>share via</p> 
         

          <FacebookShareButton className="mr-2"
          value={this.state.search.image}
          title={this.state.search.title}
          // url={"ethiopianholidays.com"}
          url={this.state.search.url}
          quote={this.state.search.title +":  "+ this.state.search.description}
          hashtag={"#EthiopianHolidays #EthiopianAirlines #HolidaysPackage"}
          >
          <FacebookIcon size={32} round={true} />
          </FacebookShareButton>
          

          <TwitterShareButton className="mr-2"
          
          url={this.state.search.url} 
          title={this.state.search.title}
          hashtag={"#EthiopianHolidays #EthiopianAirlines #HolidaysPackage"}
          
          >
          <TwitterIcon size={32} round={true} />
          </TwitterShareButton>  
        
           <EmailShareButton  className="mr-2"
          url={this.state.search.url}
          >
          <EmailIcon size={32} round={true} />
          </EmailShareButton>
        </div>
    );
  }
};


export default SocialShare;