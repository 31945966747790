import React, { Component } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import "./App.css";
import { Offline, Online } from "react-detect-offline";
import TagManager from 'react-gtm-module'

import lazy from "react-lazy-with-preload";

import Header from "./components/shared/header/header";
import Home from "./components/home/home";
import Footer from "./components/shared/footer/footer";
import FAQ from "./components/other/FAQ/FAQ";
import CommentAndComplaints from "./components/other/comment-and-complains/commentAndComplains";
import PackageEnquire from "./components/other/Equire-package/packageEnquire";
import TopDestinations from "./components/top-destinations/top-destinations";
import SpecialOfferCatagory from "./components/Package-catagory/special-offers/special-offer-catagory";
import HolidaysTypeCatagory from "./components/Package-catagory/Holidays-type/holidays-type-catagory";
import Destinations from "./components/Package-catagory/top-destinations/Destinations";
import DestinationCountry from "./components/Package-catagory/top-destinations/destination-country/destinationCountry";
import PackageList from "./components/Package-catagory/Holidays-type/package-lists/package-lists";
import PackageDetails from "./components/Package-catagory/Holidays-type/package-details/package-details";
import PaymentFormCreditCard from "./components/payment/payment-form-credit-card/paymentFormCreditCard";
import PaymentResponse from "./components/payment/payment-form-credit-card/paymentResponse";
import PaymentSummery from "./components/payment/payment-summery/paymentSummery";
import LocalPaymentSummery from "./components/payment/payment-summery-details/localPaymentSummery";
import PaymentSummeryDetails from "./components/payment/payment-summery-details/paymentSummeryDetails";
import HotelList from "./components/hotel/hotel-list";//ss
import HotelDetail from "./components/hotel/hotel-detail";
import FlightInformation from "./components/flight/flight-info";
import PackageAvailability from "./components/Package-catagory/package/packageAvailability";
import PassengerInfo from "./components/passenger-Information/passenger-information";

import { HttpService } from "./services/http/http-service";
import "bootstrap/dist/css/bootstrap.min.css";
import SideDrawer from "./components/shared/header/sidNav/sideDrawer";
import Backdrop from "./components/shared/header/backdrop/backdrop";
import ProgressLoader from "./components/shared/progress-loading/progress-loader";
import TripPlanner from "./components/trip-planner/trip-planner";
import PageNotFound from "./components/page-not-found/page-not-found";
import BundleSave from "./components/hotel/bundle-save/bundleSave";
import StopOver from "./components/home/widget/stopOver";
import Brochure from "./components/shared/brochure/brochure";
import Widget from "./components/home/widget/widget";
import PackageSearchLists from "./components/Package-catagory/search-package/search-package";
import Campaign from "./components/Package-catagory/Campaign/Campaign";
import Threed from "./components/payment/payment-form-credit-card/threeD";
import AboutUS from "./components/other/about-us/about-us";

//const tagManagerArgs = {
//    gtmId: 'GTM-MVV22NZ'
//}
//TagManager.initialize(tagManagerArgs)

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sideDrawerOpen: false,
            isLoading: true,
            isTokenLoaded: false,
        };
    }

    async componentDidMount() {

        if (!this.state.isTokenLoaded) {
            try {
                HttpService.checkToken()
                    .then(() => {
                        this.setState({ isTokenLoaded: true });
                    })
                    .catch(() => {
                        this.setState({ isTokenLoaded: true });
                    });
            } catch {
                this.setState({ isTokenLoaded: true });
            }
        }

        if (JSON.parse(localStorage.getItem("HomeSlider"))) {
        } else {
            this.getHomeSlider();
        }
        if (JSON.parse(localStorage.getItem("Destinations"))) {
        } else {
            this.getDestinations();
        }
        if (JSON.parse(localStorage.getItem("ethiopianPackages"))) {
        } else {
            this.getEthiopianPackages();
        }
        if (JSON.parse(localStorage.getItem("packageCatagories"))) {
        } else {
            this.getPackageCatagories();
        }
        if (JSON.parse(localStorage.getItem("HomeSpeacialOffers"))) {
        } else {
            this.getSelectedSpeacialOffers();
        }

        //let airportList = JSON.parse(localStorage.getItem("airports"));
        ////debugger
        //if (airportList) {
        //    if (airportList.expiresInDays) {
        //        if (Date.now() > airportList.expiresInDays) {
        //            this.getAirports();
        //        }
        //    }
        //    else
        //        this.getAirports();
        //} else {
        //    this.getAirports();
        //}
    }

    getDestinations() {

        const relativePath = "/MasterDatas/GetMasterDatas?SearchType=CONTINENT";
        HttpService.getServicePackage(relativePath)
            .then((response) => {
                let responseData = response.data;
                localStorage.setItem(
                    "Destinations",
                    JSON.stringify(responseData.continents)
                );
            })
            .catch((error) => {
                // console.log("responseData error2 ");
            });
    }

    getHomeSlider() {
       
        const relativePath2 = "/MasterDatas/GetHomeBanners";
        let queryString = "/?countryCode=ET"; 
        HttpService.getServicePackage(relativePath2, queryString)
            .then((response) => {

                //debugger
                let responseData = response.data;                
                let timeNow = Date.now();

                if (Array.isArray(responseData) && responseData.length > 0) {
                    localStorage.setItem(
                        "HomeSlider",
                        JSON.stringify({
                            sliders: responseData,
                            lastRefreshed: timeNow,
                            expiresInDays: timeNow + 1000 * 60 * 60 * 24 * 3 // 3 days
                        })
                    );

                    this.setState({
                        sliders: responseData,
                    });

                }
            })
            .catch((error) => {
                // console.log("responseData error2 ");
                //debugger
            });

       
    }



    getEthiopianPackages() {
        const relativePath2 = "/api/Packages/GetpackageInfo";
        HttpService.postServicePackage({ CountryCode: "ET" }, relativePath2)
            .then((response) => {
                let responseData = response.data;
                localStorage.setItem(
                    "ethiopianPackages",
                    JSON.stringify(responseData.list)
                );
            })
            .catch((error) => {
                // console.log("responseData error2 ");
            });
    }

    getPackageCatagories() {
        const relativePath3 =
            "/MasterDatas/GetMasterDatas?SearchType=PACKAGECATEGORY";
        HttpService.getServicePackage(relativePath3)
            .then((response) => {
                let responseData = response.data;
                localStorage.setItem(
                    "packageCatagories",
                    JSON.stringify(responseData.packageCatagories)
                );
            })
            .catch((error) => {
                // console.log("responseData error2 ");
            });
    }

    getSelectedSpeacialOffers() {
        let relativePath = "api/Packages/GetpackageInfo";
        let data = {
            PackageType: "Special Offer",
        };
        let SpeacialOffersSelected = new Array();

        HttpService.postServicePackage(data, relativePath)
            .then((response) => {
                let responseData = response.data;
                for (let l = 0; l < 3; l++) {
                    if (responseData.list[l] != null) {
                        SpeacialOffersSelected.push(responseData.list[l]);
                    }
                }
                if (SpeacialOffersSelected.length) {
                    localStorage.setItem(
                        "HomeSpeacialOffers",
                        JSON.stringify(SpeacialOffersSelected)
                    );
                } else {
                    localStorage.removeItem("HomeSpeacialOffers");
                }
            })
            .catch((error) => {
                localStorage.removeItem("HomeSpeacialOffers");
                // console.log("responseData error2 ");
            });
    }
    getAirports() {

        //debugger
        var airports= HttpService.getAirportList();

        HttpService.getAirportList()
            .then((response) => {

                //debugger

                let timeNow = Date.now();
                let responseData = response.data;
                if (responseData.length > 0) {
                    //debugger
                    localStorage.setItem(
                        "airports",
                        JSON.stringify({
                            date: responseData,
                            lastRefreshed: timeNow,
                            expiresInDays: timeNow + 1000 * 60 * 60 * 24 * 3
                        })
                    );
                }
            })
            .catch((error) => {
                //debugger
            });
    }
    drawerToggleClickHandler = () => {
        this.setState((prevState) => {
            return { sideDrawerOpen: !prevState.sideDrawerOpen };
        });
    };

    backdropClickHandler = () => {
        this.setState({ sideDrawerOpen: false });
    };

    render() {


        let backdrop;
        if (this.state.sideDrawerOpen) {
            backdrop = <Backdrop click={this.backdropClickHandler} />;
        }

        if (this.state.isTokenLoaded) {
            return (
                <div className="App" style={{ height: "100%" }}>
                    <React.Fragment>
                        <Router>

                            <Header drawerClickHandler={this.drawerToggleClickHandler} />
                            <SideDrawer show={this.state.sideDrawerOpen} />

                            {/* <Offline> <OfflinePage/></Offline>  */}
                            {backdrop}
                            <div style={{ minHeight: "400px", marginTop: "16px" }}>
                                <Switch>


                                    <Route path="/brochure" exact component={Brochure} />

                                    <Route exact path="/" component={Home} />
                                    <Route exact path="/home" component={Home} />
                                    <Route path="/trip-planner" exact component={TripPlanner} />
                                    <Route path="/flight-hotel" exact component={Widget} />
                                    <Route
                                        path="/special-offers"
                                        exact
                                        component={HolidaysTypeCatagory}
                                    />
                                    <Route
                                        path="/holidays-type/:catagory"
                                        exact
                                        component={HolidaysTypeCatagory}
                                    />
                                    <Route
                                        path="/Special-Offers/:catagory"
                                        exact
                                        component={SpecialOfferCatagory}
                                    />
                                    <Route
                                        path="/package-lists/:packageTypeId"
                                        exact
                                        component={PackageList}
                                    />
                                    <Route
                                        path="/package-details"
                                        exact
                                        component={PackageDetails}
                                    />
                                    <Route
                                        path="/top-destinations"
                                        exact
                                        component={TopDestinations}
                                    />
                                    <Route
                                        path="/destination/:continent"
                                        exact
                                        component={Destinations}
                                    />
                                    <Route
                                        path="/Country"
                                        exact
                                        component={DestinationCountry}
                                    />
                                    <Route
                                        path="/comment-and-complaint"
                                        exact
                                        component={CommentAndComplaints}
                                    />
                                    <Route
                                        path="/package-enquire"
                                        exact
                                        component={PackageEnquire}
                                    />
                                    <Route path="/flight" exact component={FlightInformation} />
                                    <Route path="/hotel" exact component={HotelList} />
                                    <Route path="/hotel-detail" exact component={HotelDetail} />
                                    <Route path="/bundAndSave" exact component={BundleSave} />
                                    <Route path="/faq" exact component={FAQ} />
                                    <Route path="/about-us" exact component={AboutUS} />
                                    <Route
                                        path="/Payment-Summery"
                                        exact
                                        component={PaymentSummery}
                                    />
                                    <Route
                                        path="/Payment-Form"
                                        exact
                                        component={PaymentFormCreditCard}
                                    />
                                    <Route
                                        path="/payment-summary-details"
                                        exact
                                        component={PaymentSummeryDetails}
                                    />
                                    <Route
                                        path="/Payment-Instruction"
                                        exact
                                        component={LocalPaymentSummery}
                                    />
                                    <Route path="/payment-3d" exact component={PaymentResponse} />
                                    <Route
                                        path="/payment-approved"
                                        exact
                                        component={PaymentResponse}
                                    />
                                    <Route
                                        path="/payment-cancelled"
                                        exact
                                        component={PaymentResponse}
                                    />
                                    <Route
                                        path="/payment-declined"
                                        exact
                                        component={PaymentResponse}
                                    />
                                    <Route
                                        path="/payment-default"
                                        exact
                                        component={PaymentResponse}
                                    />
                                    <Route
                                        path="/payment-error"
                                        exact
                                        component={PaymentResponse}
                                    />
                                    <Route
                                        path="/payment-pending"
                                        exact
                                        component={PaymentResponse}
                                    />
                                    <Route
                                        path="/payment-expired"
                                        exact
                                        component={PaymentResponse}
                                    />
                                    <Route
                                        path="/packages"
                                        exact
                                        component={PackageAvailability}
                                    />
                                    <Route
                                        path="/search-packages"
                                        exact
                                        component={PackageSearchLists}
                                    />
                                    <Route
                                        path="/Campaign"
                                        exact
                                        component={Campaign}
                                    />
                                    <Route
                                        path="/valentines-day-win-tickets"
                                        exact
                                        component={Campaign}
                                    />

                                    <Route
                                        path="/threed"
                                        exact
                                        component={Threed}
                                    />

                                    <Route path="/passenger" exact component={PassengerInfo} />
                                    <Route path="/stopover" exact component={StopOver} />
                                    {/* <Route
                  path="*"
                  exact
                  component={PageNotFound}
                /> */}
                                    <Route
                                        path="/page-not-found"
                                        exact
                                        component={PageNotFound}
                                    />
                                    <Redirect path="*" to="/page-not-found" />
                                </Switch>
                            </div>

                        </Router>

                        <Footer />
                    </React.Fragment>
                </div>
            );
        }
        return (
            <div>
                <ProgressLoader displayMess="" />
            </div>
        );
    }
}
export default App;
