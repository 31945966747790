import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { HttpService } from "./../../services/http/http-service";
class HotelSort extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPriceSortLoading: false,
      isStartSortLoading: false,
      isNearbySortLoading: false,
      priceSortMode: "decrement",
      starSortMode: "decrement",
      nearbySortMode: "decrement",
    };
  }

  async sortHotel(sortBy, sortMode) {
    
    let relativePath = "HotelAvailability/CacheAvailability";
    relativePath +=
      "?SessionId=" +
      localStorage.getItem("sessionId") +
      "&PageNumber=1&SortBy=" +
      sortBy +
      "&SortMode=" +
      sortMode;
    if (sortBy === "star") {
      await this.setState({ isStartSortLoading: true });
      await this.setState({ isPriceSortLoading: false });
      await this.setState({ isNearbySortLoading: false });
    }
    if (sortBy === "price") {
      await this.setState({ isPriceSortLoading: true });
      await this.setState({ isStartSortLoading: false });
      await this.setState({ isNearbySortLoading: false });
    }
    if (sortBy === "nearby") {
      await this.setState({ isNearbySortLoading: true });
      await this.setState({ isPriceSortLoading: false });
      await this.setState({ isStartSortLoading: false });
    }
    let sortResponse = await HttpService.getServiceHotel(relativePath);
    if (sortBy === "price") {
      if (sortMode === "decrement") {
        await this.setState({ priceSortMode: "increment" });
      } else {
        await this.setState({ priceSortMode: "decrement" });
      }
    }
    if (sortBy === "star") {
      if (sortMode === "decrement") {
        await this.setState({ starSortMode: "increment" });
      } else {
        await this.setState({ starSortMode: "decrement" });
      }
    }
    
    if (sortBy === "nearby") {
      if (sortMode === "decrement") {
        await this.setState({ nearbySortMode: "increment" });
      } else {
        await this.setState({ nearbySortMode: "decrement" });
      }
    }
    await this.setState({ isPriceSortLoading: false });
    await this.setState({ isStartSortLoading: false });
    await this.setState({ isNearbySortLoading: false });
    this.props.sortHotel(sortResponse);
  }

  renderSortlocationButton() {
    if (this.state.isNearbySortLoading) {
      return (
        <div
          className="spinner-border text-primary ml-3"
          role="status"
          id="hotel-search-loader"
        >
          <span className="sr-only">Loading...</span>
        </div>
      );
    } else if (
      this.state.isNearbySortLoading === false &&
      this.state.nearbySortMode === "increment"
    ) {
      return (
        <button
          className="btn ml-2 sort-hotel-btn"
          onClick={() => this.sortHotel("nearby", "increment")}
        >
          Nearby{" "}
          <FontAwesomeIcon
            icon={faArrowDown}
            style={{ color: "#60ac5d", fontSize: "20px" }}
          />
        </button>
      );
    }
    return (
      <button
        className="btn ml-2 sort-hotel-btn"
        onClick={() => this.sortHotel("nearby", "decrement")}
      >
        Nearby{" "}
        <FontAwesomeIcon
          icon={faArrowUp}
          style={{ color: "#60ac5d", fontSize: "20px" }}
        />
      </button>
    );
  }
  renderSortPriceButton() {
    if (this.state.isPriceSortLoading) {
      return (
        <div
          className="spinner-border text-primary ml-3"
          role="status"
          id="hotel-search-loader"
        >
          <span className="sr-only">Loading...</span>
        </div>
      );
    } else if (
      this.state.isPriceSortLoading === false &&
      this.state.priceSortMode === "increment"
    ) {
      return (
        <button
          className="btn ml-2 sort-hotel-btn"
          onClick={() => this.sortHotel("price", "increment")}
        >
          Price{" "}
          <FontAwesomeIcon
            icon={faArrowDown}
            style={{ color: "#60ac5d", fontSize: "20px" }}
          />
        </button>
      );
    }
    return (
      <button
        className="btn ml-2 sort-hotel-btn"
        onClick={() => this.sortHotel("price", "decrement")}
      >
        Price{" "}
        <FontAwesomeIcon
          icon={faArrowUp}
          style={{ color: "#60ac5d", fontSize: "20px" }}
        />
      </button>
    );
  }
  renderSortStarButton() {
    if (this.state.isStartSortLoading) {
      return (
        <div
          className="spinner-border text-primary ml-3"
          role="status"
          id="hotel-search-loader"
        >
          <span className="sr-only">Loading...</span>
        </div>
      );
    } else if (
      this.state.isStartSortLoading === false &&
      this.state.starSortMode === "increment"
    ) {
      return (
        <button
          className="btn ml-2 sort-hotel-btn"
          onClick={() => this.sortHotel("star", "increment")}
        >
          Star{" "}
          <FontAwesomeIcon
            icon={faArrowDown}
            style={{ color: "#60ac5d", fontSize: "20px" }}
          />
        </button>
      );
    }
    return (
      <button
        className="btn ml-2 sort-hotel-btn"
        onClick={() => this.sortHotel("star", "decrement")}
      >
        Star{" "}
        <FontAwesomeIcon
          icon={faArrowUp}
          style={{ color: "#60ac5d", fontSize: "20px" }}
        />
      </button>
    );
  }


  render() {
    return (
      <div className="row m-0 text-left">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 p-0">
          <span className="sort-hotel-label">Sort by : </span>
          {this.renderSortPriceButton()}
          {this.renderSortStarButton()}
          {/* {this.renderSortlocationButton()} */}
        </div>
      </div>
    );
  }
}

export default HotelSort;
