 
import React, { Component } from "react";
import {Helmet} from "react-helmet";
 
import queryString from "query-string";
class MataDecorator extends Component {
  
constructor(props){
  super(props);
 
 //  let search = queryString.parse(this.props.location.search);
  // let search = queryString.parse(this.props.metaInfo);
  let search =  this.props.metaInfo;

     this.state = { 
         search:search,
       };
 }
  render () {
    return (
        <div className="application">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{this.state.search.title}</title>
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
    <meta property="og:title" content={this.state.search.title} />
    <meta name="description" content={this.state.search.description} />
    <meta property="og:description" content={this.state.search.description} />
    <meta property="og:image" content={this.state.search.image} />
    <meta property="og:url" content={this.state.search.url} />
    <meta name="twitter:card" content={this.state.search.twitterCard} />
    <meta name="twitter:image:alt" content={this.state.search.imageAlt} />
            </Helmet> 
        </div>
    );
  }
};


export default MataDecorator;